import { useRef } from 'react';

import {
  IconButton,
  Icon,
  Link as ChakraLink,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  DrawerHeader,
} from '@chakra-ui/react';
import Image from 'next/image';

import MobileLogoSrc from '@inspire/ui/assets/original.logo.svg';
import { MenuIcon } from '@inspire/ui/icons';

import Nav from 'components/layout/Nav';
import Link from 'next/link';
import { LOGGED_IN_HOME_PATH, LOGGED_OUT_HOME_PATH } from 'lib/paths';
import { useUser } from 'lib/hooks';

const DrawerButton = () => {
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  return (
    <>
      <IconButton
        aria-label="Menu"
        icon={<Icon as={MenuIcon} boxSize={6} />}
        ref={btnRef}
        variant="ghost"
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        preserveScrollBarGap
      >
        <DrawerOverlay />
        <DrawerContent onClick={onClose}>
          <DrawerHeader textAlign="center" mb={0} pb={2}>
            <Link href={user ? LOGGED_IN_HOME_PATH : LOGGED_OUT_HOME_PATH}>
              <ChakraLink>
                <Image
                  alt="Logo INSPIRE"
                  src={MobileLogoSrc}
                  width={120}
                  height={24}
                />
              </ChakraLink>
            </Link>
          </DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody p={0}>
            <Nav />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DrawerButton;
