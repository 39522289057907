import { useRef } from 'react';

import { Button, Box, Center, Flex, useDisclosure } from '@chakra-ui/react';
import { css, keyframes } from '@emotion/react';
import TetherComponent from 'react-tether';
import { useClickAway } from 'react-use';

import ClientOnly from 'components/general/ClientOnly';
import { useBeacon } from 'lib/hooks';

const ringAnimation = keyframes`
  0% {
    opacity: 1;
    transform: scale(0.5);
  }
  80%, 100% {
    opacity: 0;
    transform: scale(1);
  }
`;

const dotAnimation = keyframes`
0% {
  transform: scale(1.3);
}
50% {
  transform: scale(1);
}
100% {
  transform: scale(1.3);
}
`;

const Beacon = ({
  id,
  children,
  tetherProps = {},
  tetherStyles = {},
  ...boxProps
}) => {
  const clickAwayRef = useRef(null);
  const { enabled, disable } = useBeacon(id);
  const {
    isOpen,
    onOpen: openTooltip,
    onClose: closeTooltip,
    onToggle: toggleTooltip,
  } = useDisclosure();
  useClickAway(clickAwayRef, () => closeTooltip());

  return (
    <ClientOnly>
      {enabled && (
        <Box
          display="inline-flex"
          verticalAlign="middle"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <TetherComponent
            attachment="middle left"
            constraints={[{ to: 'window', attachment: 'together', pin: true }]}
            style={{ zIndex: 4, ...tetherStyles }}
            renderTarget={(ref) => (
              <Center
                // @ts-ignore
                ref={ref}
                boxSize={6}
                onMouseEnter={openTooltip}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  toggleTooltip();
                }}
                cursor="pointer"
                {...boxProps}
              >
                <Box
                  pos="absolute"
                  boxSize={2.5}
                  bgColor="red"
                  borderRadius={999}
                  css={css`
                    animation: ${dotAnimation} 1.2s
                      cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.5s infinite;
                  `}
                />
                <Box
                  pos="absolute"
                  boxSize={8}
                  bgColor="red"
                  borderRadius={999}
                  css={css`
                    animation: ${ringAnimation} 1.2s
                      cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
                  `}
                />
              </Center>
            )}
            renderElement={(ref) =>
              isOpen && (
                <Box
                  // @ts-ignore
                  ref={ref}
                  bgColor="white"
                  py={3.5}
                  px={5}
                  boxShadow="0 3px 18px 6px rgb(0 0 0 / 10%)"
                  borderRadius={4}
                  maxW="320px"
                >
                  <Box ref={clickAwayRef}>
                    {children}
                    <Flex justify="center" mt={2.5}>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          disable();
                          closeTooltip();
                        }}
                        size="sm"
                      >
                        Compris !
                      </Button>
                    </Flex>
                  </Box>
                </Box>
              )
            }
            {...tetherProps}
          />
        </Box>
      )}
    </ClientOnly>
  );
};

export default Beacon;
