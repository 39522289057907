import {
  Box,
  Button,
  Center,
  Heading,
  SimpleGrid,
  Tooltip,
} from '@chakra-ui/react';

import ConditionalWrapper from '@inspire/ui/general/ConditionalWrapper';
import { sansFonts } from '@inspire/ui/static';

import { getAbsolutePath, LOGGED_IN_HOME_PATH } from 'lib/paths';
import { signIn } from 'next-auth/client';

export const isDemo = process.env.NEXT_PUBLIC_STAGE === 'demo';

export const DemoTooltip = ({
  label = "Il n'est pas possible de s'inscrire sur le site de démo",
  children,
}) => (
  <ConditionalWrapper
    condition={isDemo}
    thenWrapper={(thenChildren) => (
      <Tooltip label={label}>
        <Box>{thenChildren}</Box>
      </Tooltip>
    )}
  >
    {children}
  </ConditionalWrapper>
);

// Had to use different email addresses and added a "2" because the initial
// accounts without "2" were corrupted on CVDesignr staging. I used an admin
// account to delete some CVDesignR group associated with classes, and it
// somehow corrupted the user accounts that were in those groups. They were
// not able to join a group again or to create new groups after that.
// Also had to use different Inspire IDs for these accounts.
// All their CVDesignR passwords are "inspire123" (should only be needed
// if logging in manually on CVDesignR staging website).

export const DemoLogin = () => {
  const login = async (credentials) =>
    await signIn('credentials', {
      callbackUrl: getAbsolutePath(LOGGED_IN_HOME_PATH),
      redirect: false,
      ...credentials,
    });
  return (
    <>
      <Heading
        size="lg"
        textAlign={'center'}
        mb={5}
        fontFamily={sansFonts}
        mt={0}
      >
        Se connecter avec un compte de démo
      </Heading>
      <Center>
        <SimpleGrid columns={[1, 2, 4]} spacing="25px">
          <Box>
            {/* <Center mb={3}>
              <Image src="/img/boy.svg" alt="Lycéen Seconde" boxSize="100px" />
            </Center> */}

            <Button
              p={2}
              display="block"
              height="auto"
              width="100%"
              onClick={() =>
                login({
                  userId: 'PkfEMnfZs8brzacMF',
                  ssoId: 'secondedemo2022',
                })
              }
            >
              Lycéen.ne de Seconde
            </Button>
          </Box>
          <Box>
            {/* <Center mb={3}>
              <Image
                src="/img/girl.svg"
                alt="Lycéenne Première"
                boxSize="100px"
              />
            </Center> */}
            <Button
              p={2}
              display="block"
              height="auto"
              width="100%"
              onClick={() =>
                login({
                  userId: 'kquX7EMNRc9hXR8Kx',
                  ssoId: 'premieredemo2022',
                })
              }
            >
              Lycéen.ne de Première
            </Button>
          </Box>
          <Box>
            {/* <Center mb={3}>
              <Image
                src="/img/boy-2.svg"
                alt="Lycéen de Terminale"
                boxSize="100px"
              />
            </Center> */}
            <Button
              p={2}
              display="block"
              height="auto"
              width="100%"
              onClick={() =>
                login({
                  userId: 'xhXZfGtokdcNS4ush',
                  ssoId: 'terminaledemo2022',
                })
              }
            >
              Lycéen.ne de Terminale
            </Button>
          </Box>
          <Box>
            {/* <Center mb={3}>
              <Image src="/img/teacher.svg" alt="Professeure" boxSize="100px" />
            </Center> */}
            <Button
              p={2}
              display="block"
              height="auto"
              width="100%"
              onClick={() =>
                login({
                  // See comment above about why there is a "2"
                  userId: 'pxgHfrCFe4Execm6F',
                  ssoId: 'profdemo2022',
                })
              }
            >
              Équipe Pédagogique
            </Button>
          </Box>
        </SimpleGrid>
      </Center>
    </>
  );
};
