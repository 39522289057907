import {
  Box,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import Link from 'next/link';

import { ChevronDownIcon, PlusIcon, RightArrowIcon } from '@inspire/ui/icons';

import {
  useChangeCurrentClasse,
  useClasse,
  useClasses,
  useUser,
} from 'lib/hooks';
import { CREER_UNE_CLASSE_PATH, REJOINDRE_UNE_CLASSE_PATH } from 'lib/paths';
import { GTM_ACTION_ADD_CLASSE, GTM_CATEGORY_CLASSE, pushEvent } from 'lib/gtm';

const ClasseMenu = () => {
  const { isUserLoading, isProf, isLyceen } = useUser();
  const { classes, areClassesLoading } = useClasses();
  const { isClasseLoading, classe, refetchClasse } = useClasse();
  const { changeCurrentClasse, isChangingCurrentClasse } =
    useChangeCurrentClasse({
      onSuccess: async () => {
        await refetchClasse();
      },
    });

  const handleClickClasseItem = (id: string) => changeCurrentClasse(id);

  return (
    // https://github.com/chakra-ui/chakra-ui/issues/3440#issuecomment-851707911
    <Box>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<Icon as={ChevronDownIcon} boxSize={5} />}
          aria-label="Choisir une classe"
          colorScheme="gray"
          onClick={(e) => e.stopPropagation()}
          isLoading={
            areClassesLoading ||
            isClasseLoading ||
            isUserLoading ||
            isChangingCurrentClasse
          }
        >
          <Box
            maxW={['160px', '220px', null, '160px']}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {classe?.classeName ?? 'Choisir une classe'}
          </Box>
        </MenuButton>
        <MenuList boxShadow="0 0 5px rgba(0, 0, 0, 0.1)">
          {classes
            ?.filter((c) => c._id !== classe?._id)
            .map((c) => (
              <MenuItem
                key={c._id}
                onClick={() => handleClickClasseItem(c._id)}
              >
                {c.classeName}
              </MenuItem>
            ))}
          {isProf && (
            <Link href={CREER_UNE_CLASSE_PATH} passHref>
              <MenuItem
                icon={
                  <Icon as={PlusIcon} boxSize={5} pos="relative" top="2px" />
                }
                onClick={() =>
                  pushEvent(
                    GTM_CATEGORY_CLASSE,
                    GTM_ACTION_ADD_CLASSE,
                    'Créer classe démarrer'
                  )
                }
              >
                Créer une classe
              </MenuItem>
            </Link>
          )}
          {isLyceen && (
            <Link href={REJOINDRE_UNE_CLASSE_PATH} passHref>
              <MenuItem
                icon={
                  <Icon
                    as={RightArrowIcon}
                    boxSize={5}
                    pos="relative"
                    top="2px"
                  />
                }
              >
                Rejoindre une classe
              </MenuItem>
            </Link>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default ClasseMenu;
