/* eslint-disable */

// From https://gist.github.com/pl12133/186ec3b599aba9a541e1ab4dafc9d593

// const isInert = node =>
// node.offsetHeight <= 0 || /hidden/.test(getComputedStyle(node).getPropertyValue('visibility'))

export const focusNext = (e) => {
  // Selector lifted from `jkup/focusable.git`
  const focusable = Array.from(
      document.querySelectorAll(
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable], audio[controls], video[controls]'
      )
    ),
    step = e && e.shiftKey ? -1 : 1,
    activeIndex = focusable.indexOf(document.activeElement),
    nextActiveIndex = activeIndex + step,
    nextActive = focusable[nextActiveIndex];

  // Skip inert elements.
  // while (nextActive && isInert(nextActive)) {
  //   nextActive = focusable[(nextActiveIndex += step)]
  // }

  if (nextActive) {
    // @ts-ignore
    nextActive.focus();
    e && e.preventDefault();
  } else {
    // @ts-ignore
    document.activeElement.blur();
  }
};

export const focusPrevious = (e) => {
  // Selector lifted from `jkup/focusable.git`
  const focusable = Array.from(
      document.querySelectorAll(
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable], audio[controls], video[controls]'
      )
    ),
    step = e && e.shiftKey ? -1 : 1,
    activeIndex = focusable.indexOf(document.activeElement),
    nextActiveIndex = activeIndex - step,
    nextActive = focusable[nextActiveIndex];

  // Skip inert elements.
  // while (nextActive && isInert(nextActive)) {
  //   nextActive = focusable[(nextActiveIndex += step)]
  // }

  if (nextActive) {
    // @ts-ignore
    nextActive.focus();
    e && e.preventDefault();
  } else {
    // @ts-ignore
    document.activeElement.blur();
  }
};
