import { useState } from 'react';

import {
  Box,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
} from '@chakra-ui/react';
import { signOut } from 'next-auth/client';
import Link from 'next/link';

import {
  LogoutIcon,
  NotesIcon,
  ProfileIcon,
  UserIcon,
} from '@inspire/ui/icons';

import {
  getAbsolutePath,
  LOG_OUT_PATH,
  MON_PROFIL_PATH,
  MES_REPONSES_PATH,
  ACCEUIL_SECONDE_COLLEGE,
} from 'lib/paths';
import { useClasse, useIsHorsScope, useUser } from 'lib/hooks';

import { ROLE_SSO_REGISTERED } from '@inspire/data/static/roles';
import { useRedirectIfAllowed } from 'components/auth/Redirects';

const UserMenu = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { user, isLyceen } = useUser();
  const { classeHasModules } = useClasse();
  const { isHorsScope } = useIsHorsScope();

  const isSecondOrCollege = isLyceen && isHorsScope;
  useRedirectIfAllowed(ACCEUIL_SECONDE_COLLEGE, isSecondOrCollege);

  const name = `${user?.profile?.firstName ?? ''} ${
    user?.profile?.lastName?.[0] ?? ''
  }.`;

  return (
    // https://github.com/chakra-ui/chakra-ui/issues/3440#issuecomment-851707911
    <Box>
      <Menu>
        <MenuButton
          as={IconButton}
          isLoading={isLoggingOut}
          icon={<Icon as={UserIcon} boxSize={6} />}
          aria-label="Menu utilisateur"
          variant="ghost"
          isRound
        />
        <MenuList boxShadow="0 0 5px rgba(0, 0, 0, 0.1)">
          <MenuItem
            tabIndex={-1}
            justifyContent="center"
            cursor="default"
            bg="white !important"
            _hover={{ bg: 'white' }}
          >
            <b>{name}</b>
          </MenuItem>
          {!user?.roles?.includes(ROLE_SSO_REGISTERED) && (
            <Link href={MON_PROFIL_PATH} passHref>
              <MenuItem
                icon={
                  <Icon as={ProfileIcon} boxSize={5} pos="relative" top="2px" />
                }
              >
                Mon compte
              </MenuItem>
            </Link>
          )}
          {isLyceen && classeHasModules && (
            <Link href={MES_REPONSES_PATH} passHref>
              <MenuItem
                icon={
                  <Icon as={NotesIcon} boxSize={5} pos="relative" top="2px" />
                }
              >
                Mes réponses
              </MenuItem>
            </Link>
          )}
          <MenuItem
            icon={<Icon as={LogoutIcon} boxSize={5} pos="relative" top="2px" />}
            onClick={() => {
              setIsLoggingOut(true);
              signOut({ callbackUrl: getAbsolutePath(LOG_OUT_PATH) });
            }}
          >
            Déconnexion
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default UserMenu;
