import {
  Box,
  Flex,
  FlexProps,
  HStack,
  Spacer,
  Center,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import Image from 'next/image';

import LogoSrc from '@inspire/ui/assets/logo.svg';
import { LeftArrowIcon, MailIcon } from '@inspire/ui/icons';

import LinkButton from 'components/general/LinkButton';
import Link from 'components/general/Link';
import Nav from 'components/layout/Nav';
import UserMenu from 'components/layout/UserMenu';
import FakeSearchInput from 'components/search/FakeSearchInput';
import { CHAT_PATH, LOGGED_IN_HOME_PATH } from 'lib/paths';
import { useMemoryStore } from 'lib/store';
import {
  useInfiniteContactRequests,
  useIsEclaireurProfileComplete,
  useIsQuestionnaireComplete,
  useUser,
} from 'lib/hooks';

export const AppSidenav = () => (
  <Box position="sticky" top={5} w={[200, 200, 200, 250]}>
    <Center mb={30} flexDir="column">
      <Link href={LOGGED_IN_HOME_PATH} lineHeight={0}>
        <Image alt="Logo INSPIRE" src={LogoSrc} width={279} height={67} />
      </Link>
    </Center>
    <Nav />
  </Box>
);

export const AppSideNavOnDesktop = () => (
  <Box
    display={['none', null, 'block']}
    boxShadow="1px 0 8px rgb(0 0 0 / 10%)"
    backgroundColor="white"
    zIndex={4}
    minH="100vh"
  >
    <AppSidenav />
  </Box>
);

const BackButton = () => {
  const backButton = useMemoryStore((s) => s.backButton);

  return backButton?.path ? (
    <LinkButton
      data-test="back-button"
      leftIcon={<Icon as={LeftArrowIcon} boxSize={6} />}
      href={backButton.path}
      variant="ghost"
    >
      {backButton?.label ?? 'Retour'}
    </LinkButton>
  ) : null;
};

export const AppDesktopHeader = (flexProps: FlexProps) => {
  const { isEclaireur, isLyceen } = useUser();
  const isEclaireurProfileComplete = useIsEclaireurProfileComplete();
  const isQuestionnaireComplete = useIsQuestionnaireComplete();
  const areMessagesEnabled =
    (isLyceen || isEclaireur) &&
    isEclaireurProfileComplete &&
    isQuestionnaireComplete;
  const { contactRequests } = useInfiniteContactRequests(null, {
    enabled: areMessagesEnabled,
    refetchInterval: 60 * 1000,
  });
  const newMessageCount = contactRequests?.filter(
    (cr) => cr.hasNewMessage
  )?.length;

  return (
    <Flex
      position="fixed"
      right={0}
      left={0}
      top={0}
      backgroundColor="white"
      boxShadow="0 1px 8px rgb(0 0 0 / 10%)"
      p={3}
      zIndex={3}
      {...flexProps}
    >
      <BackButton />
      <Spacer />
      <HStack>
        {isEclaireurProfileComplete && isQuestionnaireComplete && (
          <FakeSearchInput />
        )}
        {areMessagesEnabled && (
          <Link href={CHAT_PATH} pos="relative">
            {newMessageCount > 0 && (
              <Center
                pos="absolute"
                right="2px"
                top="2px"
                bgColor="red"
                zIndex="2"
                color="white"
                borderRadius={999}
                boxSize={4}
                fontSize="12px"
              >
                {newMessageCount}
              </Center>
            )}
            {isEclaireurProfileComplete && (
              <IconButton
                isRound
                icon={<Icon as={MailIcon} boxSize={5} />}
                aria-label="Messages"
                variant="ghost"
              />
            )}
          </Link>
        )}
        <UserMenu />
      </HStack>
    </Flex>
  );
};

export const AppHeaderOnDesktop = () => (
  <AppDesktopHeader display={['none', null, 'flex']} />
);
