import { Ref } from 'react';

import {
  chakra,
  Box,
  BoxProps,
  Icon,
  Text,
  forwardRef,
  HTMLChakraProps,
} from '@chakra-ui/react';
import { useHotkeys } from 'react-hotkeys-hook';

import ModalTrigger from '@inspire/ui/chakra/modal/ModalTrigger';
import { SearchIcon } from '@inspire/ui/icons';

import SearchModalContent from 'components/search/SearchModalContent';
import { useClasse, useUser } from 'lib/hooks';
import {
  GTM_ACTION_OUVERTURE_RECHERCHE,
  GTM_CATEGORY_RECHERCHE,
  pushEvent,
} from 'lib/gtm';

export const FakeInputButton = forwardRef(
  (props: HTMLChakraProps<'button'>, ref: Ref<HTMLButtonElement>) => (
    <chakra.button
      data-cy="open-search-dialog"
      w="full"
      type="button"
      role="search"
      ref={ref}
      display="flex"
      alignItems="center"
      color="gray.400"
      h="40px"
      px={3}
      bg="white"
      outline="0"
      _focus={{ shadow: 'outline' }}
      rounded="md"
      aria-label="Search"
      border="1px solid #ddd"
      _hover={{ borderColor: '#aaa' }}
      {...props}
    >
      <Icon as={SearchIcon} boxSize={5} mr={2} />
      <Text my={2}>{props.placeholder}</Text>
    </chakra.button>
  )
);

const FakeSearchButtonContainer = ({ openModal, placeholder }) => {
  useHotkeys('ctrl+k, cmd+k', () => {
    openModal();
  });

  return (
    <FakeInputButton
      onClick={() => {
        pushEvent(
          GTM_CATEGORY_RECHERCHE,
          GTM_ACTION_OUVERTURE_RECHERCHE,
          'ouverture recherche'
        );
        openModal();
      }}
      placeholder={placeholder}
    />
  );
};

const FakeSearchInput = (boxProps: BoxProps) => {
  const { isProf, isLyceen } = useUser();
  const { classe, classeIsGatlRc } = useClasse();
  const isActivitesEnabled = classeIsGatlRc && (isProf || (isLyceen && classe));
  const isElevesEnabled = isProf && classe;

  // There is also a label in SearchModalContent.tsx
  const placeholder = isElevesEnabled
    ? 'Rechercher une piste, un article, une activité, un élève'
    : isActivitesEnabled
    ? 'Rechercher une piste, un article, une activité'
    : 'Rechercher une piste ou un article';

  // Match the placeholder length approximately
  // Borders, margins, icon, icon margin
  const placeholderFixedMinWidth = 1 + 12 + 20 + 8 + 12 + 1; // = 54px
  const characterWidthAverage = 6.5;

  return (
    <Box
      w={`${Math.round(
        placeholderFixedMinWidth + placeholder.length * characterWidthAverage
      )}px`}
      {...boxProps}
    >
      <ModalTrigger
        trigger={(openModal) => (
          <FakeSearchButtonContainer
            openModal={openModal}
            placeholder={placeholder}
          />
        )}
        modalProps={{ size: '2xl' }}
      >
        {(closeModal) => <SearchModalContent closeModal={closeModal} />}
      </ModalTrigger>
    </Box>
  );
};

export default FakeSearchInput;
