import {
  Box,
  Icon,
  VStack,
  StackProps,
  Flex,
  Badge,
  Center,
  Tooltip,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Image,
} from '@chakra-ui/react';

import Skeleton from '@inspire/ui/chakra/layout/Skeleton';
import {
  CheckCircleIcon,
  CompassIcon,
  HomeIcon,
  MailIcon,
  NewsIcon,
  InfoIcon,
  // NotesIcon,
  PeopleIcon,
  QuestionCircleIcon,
  // QuestionCircleIcon,
  RightArrowIcon,
  SchoolIcon,
  StudentIcon,
  WarningIcon,
  FacebookIcon,
  ContractIcon,
  ProfileIcon,
  KitSurvieIcon,
  // PrintempsOrientationIcon,
  FormIcon,
  ChevronRight,
  ChevronDownIcon,
  FichesActivitesIcon,
  ChessIcon,
  // PrintempsOrientationIcon,
} from '@inspire/ui/icons';
import Link from 'next/link';

import Beacon from 'components/general/Beacon';
import LinkButton, { LinkButtonProps } from 'components/general/LinkButton';
import ClasseMenu from 'components/layout/ClasseMenu';
import {
  useClasse,
  useInfiniteContactRequests,
  useIsEclaireurProfileComplete,
  useIsQuestionnaireComplete,
  useUser,
  useMyProfile,
} from 'lib/hooks';
import { BEACON_PROF_NAV_MODULES } from 'lib/local-store-keys';
import {
  CONNEXION_PATH,
  CREER_UNE_CLASSE_PATH,
  ECLAIREURS_PATH,
  FAQ_PATH,
  INSCRIPTION_PATH,
  MA_CLASSE_PATH,
  MES_ELEVES_PATH,
  MES_MODULES_PATH,
  PISTES_PATH,
  REJOINDRE_UNE_CLASSE_PATH,
  KIT_DE_SURVIE_PATH,
  LOGGED_IN_HOME_PATH,
  ARTICLES_PATH,
  CHAT_PATH,
  GROUPE_ECLAIREUR_PATH,
  ETRE_ECLAIREUR_PATH,
  CHARTE_ECLAIREUR_PATH,
  PROFIL_ECLAIREUR_PATH,
  questionnaireStepPath,
  FICHES_ACTIVITES_PATH,
  // PRINTEMPS_ORIENTATION_PATH,
  LANDING_PAGE_ETUDIANT,
  LANDING_PAGE_EQUIPE_PEDAGOGIQUE,
  LANDING_PAGE_LYCEEN,
} from 'lib/paths';

import { useRouter } from 'next/router';
import {
  GTM_ACTION_ADD_CLASSE,
  GTM_CATEGORY_CLASSE,
  GTM_CATEGORY_INSCRIPTION,
  GTM_ACTION_INSCRIPTION,
  GTM_CATEGORY_CONNEXION,
  GTM_ACTION_CONNEXION,
  GTM_ACTION_VOIR_ELEVES,
  GTM_CATEGORY_RESSOURCES,
  GTM_ACTION_CONSULTATION_RESSOURCE,
  pushEvent,
} from 'lib/gtm';
import { DemoTooltip, isDemo } from 'components/auth/DemoLogin';

const NavLink = ({
  children,
  href,
  icon,
  extraMatchRule,
  iconProps,
  ...linkButtonProps
}: {
  icon?: any;
  iconProps?: any;
  extraMatchRule?: (path: string) => boolean;
} & LinkButtonProps) => {
  const { asPath } = useRouter();
  const isMatch = href === asPath || extraMatchRule?.(asPath);

  return (
    <LinkButton
      leftIcon={icon && <Icon as={icon} {...iconProps} />}
      href={href}
      variant="ghost"
      isFullWidth
      justifyContent="left"
      color="black"
      pl={6}
      h={9}
      bgColor={isMatch && 'yellow.200'}
      {...linkButtonProps}
    >
      {children}
    </LinkButton>
  );
};

const BigNavLink = ({
  children,
  href,
  ...linkButtonProps
}: {
  icon?: any;
} & LinkButtonProps) => (
  <LinkButton
    href={href}
    variant="outline"
    justifyContent="left"
    isFullWidth
    color="black"
    px={6}
    h={16}
    pos="relative"
    overflow="hidden"
    _hover={{ bgColor: 'yellow.200' }}
    rounded="12px"
    borderColor="#dcc8b7"
    {...linkButtonProps}
  >
    {children}
  </LinkButton>
);

const Nav = (stackProps: StackProps) => {
  const {
    isOpen: isInfoMenuOpen,
    onToggle: toggleInfoMenu,
    onClose: closeInfoMenu,
  } = useDisclosure();
  const { user, isUserLoading, isLyceen, isProf, isEclaireur } = useUser();
  const isEclaireurProfileComplete = useIsEclaireurProfileComplete();
  const isQuestionnaireComplete = useIsQuestionnaireComplete();
  const { myProfile } = useMyProfile();

  const {
    isClasseLoading,
    classe,
    classeIsGatlRc,
    classeHasModules,
    isSeconde,
  } = useClasse();
  const { contactRequests } = useInfiniteContactRequests(null, {
    enabled: Boolean((user && !classeIsGatlRc) || isEclaireur),
    refetchInterval: 60 * 1000,
  });

  const isNavReady = !isUserLoading && !isClasseLoading;
  const newMessageCount = contactRequests?.filter(
    (cr) => cr.hasNewMessage
  )?.length;

  return (
    <VStack
      direction="column"
      alignItems="start"
      w="100%"
      spacing={1}
      {...stackProps}
    >
      {isNavReady ? (
        <>
          {!user && (
            <VStack px={3} w="full" mb={3} spacing={4}>
              <BigNavLink href={LANDING_PAGE_LYCEEN}>
                <Box pos="relative" zIndex={1}>
                  Lycéen
                </Box>
                <Box lineHeight={0} pos="absolute" right="4px" top="4px">
                  <Image
                    h="74px"
                    src="/img/landing-pages/lyceen.png"
                    alt="Lycéen"
                  />
                </Box>
              </BigNavLink>
              <BigNavLink href={LANDING_PAGE_EQUIPE_PEDAGOGIQUE}>
                <Box pos="relative" zIndex={1}>
                  Équipe pédagogique
                </Box>
                <Box
                  textAlign="center"
                  lineHeight={0}
                  pos="absolute"
                  right="16px"
                  top="5px"
                >
                  <Image
                    h="74px"
                    src="/img/landing-pages/main-prof.svg"
                    alt="Équipe pédagogique"
                  />
                </Box>
              </BigNavLink>
              <BigNavLink href={LANDING_PAGE_ETUDIANT}>
                <Box pos="relative" zIndex={1}>
                  Étudiant
                </Box>
                <Box
                  textAlign="center"
                  lineHeight={0}
                  pos="absolute"
                  right="-40px"
                  top="-20px"
                >
                  <Image
                    h="100px"
                    opacity={0.9}
                    src="/img/landing-pages/etudiant-yellow.svg"
                    alt="Étudiant Éclaireur"
                  />
                </Box>
              </BigNavLink>
            </VStack>
          )}
          {isProf && (
            <Flex w="full" align="center" justify="center" mb={3}>
              <ClasseMenu />
            </Flex>
          )}
          {user && isEclaireurProfileComplete && isQuestionnaireComplete && (
            <NavLink href={LOGGED_IN_HOME_PATH} icon={HomeIcon}>
              Accueil
            </NavLink>
          )}
          {isProf && !isClasseLoading && !classe && (
            <NavLink
              href={CREER_UNE_CLASSE_PATH}
              icon={RightArrowIcon}
              onClick={() =>
                pushEvent(
                  GTM_CATEGORY_CLASSE,
                  GTM_ACTION_ADD_CLASSE,
                  'Créer classe démarrer'
                )
              }
            >
              Créer une classe
            </NavLink>
          )}
          {isProf && classe && (
            <NavLink href={MA_CLASSE_PATH} icon={SchoolIcon}>
              Ma classe
            </NavLink>
          )}
          {user && classe && classeHasModules && (
            <NavLink
              href={MES_MODULES_PATH}
              icon={ChessIcon}
              extraMatchRule={(path) => path.startsWith('/module/')}
            >
              Mes modules
              {isProf && (
                <Beacon
                  id={BEACON_PROF_NAV_MODULES}
                  ml={1}
                  // Chakra's navbar overlay is 1400
                  tetherStyles={{ zIndex: 1500 }}
                >
                  Découvrez les modules et cliquez sur celui que vous souhaitez
                  découvrir. Un module = une séance en classe = 50 min max.
                </Beacon>
              )}
            </NavLink>
          )}
          {isProf && classe && (
            <NavLink
              href={MES_ELEVES_PATH}
              extraMatchRule={(path) => path.startsWith('/eleve/')}
              icon={PeopleIcon}
              onClick={() => {
                pushEvent(
                  GTM_CATEGORY_CLASSE,
                  GTM_ACTION_VOIR_ELEVES,
                  'Mes élèves (menu)'
                );
              }}
            >
              Mes élèves
            </NavLink>
          )}
          {isLyceen &&
            classe &&
            classe?.niveau !== 'seconde' &&
            !isQuestionnaireComplete && (
              <NavLink href={questionnaireStepPath(1)} icon={FormIcon}>
                Questionnaire
              </NavLink>
            )}
          {user && isQuestionnaireComplete && !isEclaireur && !isSeconde && (
            <NavLink
              href={PISTES_PATH}
              icon={CompassIcon}
              extraMatchRule={(path) => path.startsWith('/piste')}
            >
              {`${isLyceen ? 'Mes' : 'Les'}
                 pistes 
                ${
                  myProfile.filiere === 'bac_pro' || classeHasModules
                    ? "d'avenir"
                    : "d'études"
                }`}
            </NavLink>
          )}
          {isLyceen && isQuestionnaireComplete && (
            <NavLink href={ECLAIREURS_PATH} icon={StudentIcon}>
              Les Éclaireurs
            </NavLink>
          )}
          {isEclaireur && (
            <>
              <NavLink href={PROFIL_ECLAIREUR_PATH} icon={ProfileIcon}>
                Mon profil Éclaireur
              </NavLink>
              {!isEclaireurProfileComplete && (
                <>
                  <NavLink href={CHARTE_ECLAIREUR_PATH} icon={ContractIcon}>
                    Charte de l'Éclaireur
                  </NavLink>
                  <NavLink href={ETRE_ECLAIREUR_PATH} icon={CheckCircleIcon}>
                    Être Éclaireur
                  </NavLink>
                </>
              )}
            </>
          )}
          {((isLyceen && isQuestionnaireComplete) ||
            (isEclaireur && isEclaireurProfileComplete)) && (
            <NavLink
              href={CHAT_PATH}
              icon={MailIcon}
              position="relative"
              extraMatchRule={(path) => path.startsWith('/echange')}
            >
              Mes messages{' '}
              {newMessageCount > 0 && (
                <Center
                  bgColor="red"
                  zIndex="2"
                  color="white"
                  borderRadius={999}
                  boxSize={4}
                  fontSize="12px"
                  ml={2}
                >
                  {newMessageCount}
                </Center>
              )}
            </NavLink>
          )}
          {isLyceen && !isClasseLoading && !classe && (
            <NavLink href={REJOINDRE_UNE_CLASSE_PATH} icon={RightArrowIcon}>
              Rejoindre une classe
            </NavLink>
          )}
          {(isQuestionnaireComplete ||
            classeIsGatlRc ||
            (classe && classe.niveau === 'seconde')) &&
            isEclaireurProfileComplete && (
              <Box w="100%">
                {/*     // https://github.com/chakra-ui/chakra-ui/issues/3440#issuecomment-851707911             */}
                <Menu
                  isOpen={isInfoMenuOpen}
                  onClose={closeInfoMenu}
                  gutter={0}
                >
                  <MenuButton
                    as={Button}
                    leftIcon={<Icon as={InfoIcon} />}
                    rightIcon={
                      isInfoMenuOpen ? (
                        <Icon as={ChevronDownIcon} boxSize={5} mr={2} />
                      ) : (
                        <Icon as={ChevronRight} mr={2} />
                      )
                    }
                    variant="ghost"
                    isFullWidth
                    color="black"
                    pl={6}
                    h={9}
                    textAlign="left"
                    alignItems="center"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleInfoMenu();
                    }}
                  >
                    Les ressources
                  </MenuButton>

                  <MenuList boxShadow="0 0 5px rgba(0, 0, 0, 0.1) !important">
                    {!user && (
                      <Link href={PISTES_PATH} passHref>
                        <MenuItem
                          icon={
                            <Icon
                              as={CompassIcon}
                              boxSize={4}
                              pos="relative"
                              top="3px"
                            />
                          }
                          onClick={() => {
                            pushEvent(
                              GTM_CATEGORY_RESSOURCES,
                              GTM_ACTION_CONSULTATION_RESSOURCE,
                              "Pistes d'études"
                            );
                          }}
                        >
                          Pistes d'études
                        </MenuItem>
                      </Link>
                    )}
                    {isEclaireur && (
                      <>
                        <Link href={CHARTE_ECLAIREUR_PATH}>
                          <MenuItem
                            icon={
                              <Icon
                                as={ContractIcon}
                                boxSize={4}
                                pos="relative"
                                top="3px"
                              />
                            }
                            onClick={() => {
                              pushEvent(
                                GTM_CATEGORY_RESSOURCES,
                                GTM_ACTION_CONSULTATION_RESSOURCE,
                                "Charte de l'Éclaireur"
                              );
                            }}
                          >
                            Charte de l'Éclaireur
                          </MenuItem>
                        </Link>
                        <Link href={ETRE_ECLAIREUR_PATH}>
                          <MenuItem
                            icon={
                              <Icon
                                as={CheckCircleIcon}
                                boxSize={4}
                                pos="relative"
                                top="3px"
                              />
                            }
                            onClick={() => {
                              pushEvent(
                                GTM_CATEGORY_RESSOURCES,
                                GTM_ACTION_CONSULTATION_RESSOURCE,
                                'Être Éclaireur'
                              );
                            }}
                          >
                            Être Éclaireur
                          </MenuItem>
                        </Link>
                        <Link href={GROUPE_ECLAIREUR_PATH} passHref>
                          <MenuItem
                            icon={
                              <Icon
                                as={FacebookIcon}
                                boxSize={4}
                                pos="relative"
                                top="3px"
                              />
                            }
                            onClick={() => {
                              pushEvent(
                                GTM_CATEGORY_RESSOURCES,
                                GTM_ACTION_CONSULTATION_RESSOURCE,
                                'Le groupe des Éclaireurs'
                              );
                            }}
                          >
                            Le groupe des Éclaireurs
                          </MenuItem>
                        </Link>
                      </>
                    )}
                    {isProf && (
                      <Link href={FICHES_ACTIVITES_PATH} passHref>
                        <MenuItem
                          icon={
                            <Icon
                              as={FichesActivitesIcon}
                              boxSize={4}
                              pos="relative"
                              top="2px"
                            />
                          }
                          onClick={() => {
                            pushEvent(
                              GTM_CATEGORY_RESSOURCES,
                              GTM_ACTION_CONSULTATION_RESSOURCE,
                              'Fiches activités'
                            );
                          }}
                        >
                          Fiches activités
                        </MenuItem>
                      </Link>
                    )}
                    {!isEclaireur && (
                      <Link href={KIT_DE_SURVIE_PATH} passHref>
                        <MenuItem
                          icon={
                            <Icon
                              as={KitSurvieIcon}
                              boxSize={4}
                              pos="relative"
                              top="2px"
                            />
                          }
                          onClick={() => {
                            pushEvent(
                              GTM_CATEGORY_RESSOURCES,
                              GTM_ACTION_CONSULTATION_RESSOURCE,
                              'Kit de survie'
                            );
                          }}
                        >
                          Kit de survie
                        </MenuItem>
                      </Link>
                    )}
                    <Link href={ARTICLES_PATH} passHref>
                      <MenuItem
                        icon={
                          <Icon
                            as={NewsIcon}
                            boxSize={4}
                            pos="relative"
                            top="2px"
                          />
                        }
                        onClick={() => {
                          pushEvent(
                            GTM_CATEGORY_RESSOURCES,
                            GTM_ACTION_CONSULTATION_RESSOURCE,
                            'Le blog'
                          );
                        }}
                      >
                        Le blog
                      </MenuItem>
                    </Link>
                    <Link href={FAQ_PATH} passHref>
                      <MenuItem
                        icon={
                          <Icon
                            as={QuestionCircleIcon}
                            boxSize={4}
                            pos="relative"
                            top="2px"
                          />
                        }
                        onClick={() => {
                          pushEvent(
                            GTM_CATEGORY_RESSOURCES,
                            GTM_ACTION_CONSULTATION_RESSOURCE,
                            'FAQ'
                          );
                        }}
                      >
                        FAQ
                      </MenuItem>
                    </Link>
                    {/* {process.env.NEXT_PUBLIC_ENABLE_PRINTEMPS_ORIENTATION &&
                      !isEclaireur && (
                        <Link href={PRINTEMPS_ORIENTATION_PATH} passHref>
                          <MenuItem
                            icon={
                              <Icon
                                as={PrintempsOrientationIcon}
                                boxSize={4}
                                pos="relative"
                                top="3px"
                              />
                            }
                            onClick={() => {
                              pushEvent(
                                GTM_CATEGORY_RESSOURCES,
                                GTM_ACTION_CONSULTATION_RESSOURCE,
                                "Printemps de l'orientation"
                              );
                            }}
                          >
                            Printemps de l'orientation
                          </MenuItem>
                        </Link>
                      )} */}
                  </MenuList>
                </Menu>
              </Box>
            )}
          {!isUserLoading && !user && (
            <Box px={3} w="100%">
              <DemoTooltip>
                <LinkButton
                  isDisabled={isDemo}
                  rounded="full"
                  href={INSCRIPTION_PATH}
                  isFullWidth
                  mt={3}
                  onClick={() => {
                    pushEvent(
                      GTM_CATEGORY_INSCRIPTION,
                      GTM_ACTION_INSCRIPTION,
                      "Je m'inscris (menu mobile)"
                    );
                  }}
                >
                  Je m'inscris
                </LinkButton>
              </DemoTooltip>
              <LinkButton
                href={CONNEXION_PATH}
                rounded="full"
                colorScheme="gray"
                color="#333"
                isFullWidth
                mt={3}
                onClick={() => {
                  pushEvent(
                    GTM_CATEGORY_CONNEXION,
                    GTM_ACTION_CONNEXION,
                    'Je me connecte (menu mobile)'
                  );
                }}
              >
                Je me connecte
              </LinkButton>
            </Box>
          )}
        </>
      ) : (
        <>
          <Box h={9} px={6} w="100%" py={2.5}>
            <Skeleton containerProps={{ h: '100%' }} h="100%" />
          </Box>
          <Box h={9} px={6} w="100%" py={2.5}>
            <Skeleton containerProps={{ h: '100%' }} h="100%" />
          </Box>
          <Box h={9} px={6} w="100%" py={2.5}>
            <Skeleton containerProps={{ h: '100%' }} h="100%" />
          </Box>
          <Box h={9} px={6} w="100%" py={2.5}>
            <Skeleton containerProps={{ h: '100%' }} h="100%" />
          </Box>
        </>
      )}

      {process.env.NEXT_PUBLIC_STAGE === 'demo' && (
        <Center w="100%">
          <Tooltip label="Les données sont susceptibles d'être supprimées.">
            <Badge colorScheme="red" mt={8} py={1} px={2}>
              <Icon as={WarningIcon} position="relative" top="2px" mr={1} />
              Environnement de Démo
            </Badge>
          </Tooltip>
        </Center>
      )}
    </VStack>
  );
};

export default Nav;
