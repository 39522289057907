// Those keys are automatically prefixed by beacon- by the useBeacon hook
export const BEACON_INVITER_ELEVES = 'inviter-eleves';
export const BEACON_PROF_NAV_MODULES = 'prof-mes-modules';
export const BEACON_FIRST_ELEVE = 'first-eleve';
export const BEACON_MODULE_SWITCH = 'module-switch';
export const BEACON_PISTES_ETUDES = 'pistes-etudes';

export const BEACON_SELECTIONNER_PISTE = 'selectionner-piste';
export const BEACON_EXPERIENCES_A_PARTAGER = 'experiences-a-partager';
export const BEACON_QUESTIONS_ETUDIANTES = 'questions-etudiantes';

export const PRIVACY_CONSENT = 'privacy-consent';
