import axios from 'axios';
import { getCookie } from './cookies';

export const accessSSO = async (options: {
  targetUrl: string; // The url to redirect to after login
}) => {
  let targetUrl = options.targetUrl;
  if (!targetUrl.startsWith('http')) {
    targetUrl = `${process.env.NEXT_PUBLIC_BASE_URL}${options.targetUrl}`;
  }

  // Call the back of the front (nextjs backend) for the user to be redirected to the sso
  const { data } = await axios.post('/api/auth/sso/request-auth', {
    targetUrl,
  });

  // Redirect to the SSO including the visitorId
  let url = data.redirectUrl;
  const visitorId = getCookie('visitorId');
  if (url.includes('?')) {
    url = `${url}&visitorId=${visitorId}`;
  } else {
    url = `${url}?visitorId=${visitorId}`;
  }

  window.location = url;
};

export const disconnectSSO = async (options: {
  targetUrl: string; // The url to redirect to after login
}) => {
  let targetUrl = options.targetUrl;
  if (!targetUrl.startsWith('http')) {
    targetUrl = `${process.env.NEXT_PUBLIC_BASE_URL}${options.targetUrl}`;
  }

  // Call the back of the front (nextjs backend) for the user to be redirected to the sso
  const { data } = await axios.post('/api/auth/sso/request-disconnect', {
    targetUrl,
  });

  // Redirect to the SSO
  window.location = data.redirectUrl;
};
