import { useState, useEffect } from 'react';

import {
  Avatar,
  Box,
  Center,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { isGatl, isRc, userProfileImage } from '@inspire/data/helpers/user';
import { SearchIcon } from '@inspire/ui/icons';
import Spinner from '@inspire/ui/chakra/Spinner';

import Link from 'components/general/Link';
import { focusNext, focusPrevious } from 'lib/focus';
import { useModules, useMyProfile } from 'lib/hooks';
import { lowerAscii } from 'lib/util';
import { User } from 'lib/generated';
import {
  activitePath,
  activiteStepPath,
  articlePath,
  elevePath,
  pistePath,
  eclaireurPath,
  recherchePath,
} from 'lib/paths';
import { useClasse, useUser, useAutocomplete } from 'lib/hooks';
import {
  GTM_ACTION_RECHERCHE,
  GTM_CATEGORY_RECHERCHE,
  pushEvent,
} from 'lib/gtm';

const getActivitePath = (activite) =>
  activite.steps
    ? activiteStepPath(activite.moduleId, activite.activiteId, 1)
    : activitePath(activite.moduleId, activite.activiteId);

const EleveResult = ({ closeModal, eleve }) => (
  <Link
    href={elevePath(eleve._id)}
    onClick={() => {
      // pushEvent(
      //   GTM_CATEGORY_RECHERCHE,
      //   GTM_ACTION_RECHERCHE_SELECTION_LYCEEN,
      //   'Sélection lycéen'
      // );
      closeModal();
    }}
    display="flex"
    alignItems="center"
    borderRadius={4}
    textDecor="none"
    px={3}
    py={2}
    fontWeight="normal"
    _focus={{
      bgColor: 'gray.100',
      zIndex: 1,
      boxShadow: 'var(--chakra-shadows-outline)',
    }}
    _hover={{ textDecor: 'none', bgColor: 'gray.100' }}
  >
    {eleve.profile?.firstName} {eleve.profile?.lastName}
  </Link>
);

const PisteResult = ({ closeModal, piste }) => (
  <Link
    href={pistePath(piste.slug)}
    onClick={() => {
      pushEvent(
        GTM_CATEGORY_RECHERCHE,
        GTM_ACTION_RECHERCHE,
        'recherche piste',
        piste.slug
      );
      closeModal();
    }}
    display="flex"
    alignItems="center"
    borderRadius={4}
    textDecor="none"
    px={3}
    py={2}
    fontWeight="normal"
    _focus={{
      bgColor: 'gray.100',
      zIndex: 1,
      boxShadow: 'var(--chakra-shadows-outline)',
    }}
    _hover={{ textDecor: 'none', bgColor: 'gray.100' }}
  >
    {piste.title}
  </Link>
);

const ArticleResult = ({ closeModal, article }) => (
  <Link
    href={articlePath(article.slug)}
    onClick={() => {
      pushEvent(
        GTM_CATEGORY_RECHERCHE,
        GTM_ACTION_RECHERCHE,
        'recherche article',
        article.slug
      );
      closeModal();
    }}
    display="flex"
    alignItems="center"
    borderRadius={4}
    textDecor="none"
    px={3}
    py={2}
    fontWeight="normal"
    _focus={{
      bgColor: 'gray.100',
      zIndex: 1,
      boxShadow: 'var(--chakra-shadows-outline)',
    }}
    _hover={{ textDecor: 'none', bgColor: 'gray.100' }}
  >
    {article.title}
  </Link>
);

const ActiviteResult = ({ closeModal, activite }) => (
  <Link
    href={getActivitePath(activite)}
    onClick={() => {
      // pushEvent(
      //   GTM_CATEGORY_RECHERCHE,
      //   GTM_ACTION_RECHERCHE_SELECTION_ACTIVITE,
      //   'Sélection activité',
      //   activite.activiteId
      // );
      closeModal();
    }}
    display="flex"
    alignItems="center"
    borderRadius={4}
    textDecor="none"
    px={3}
    py={2}
    fontWeight="normal"
    _focus={{
      bgColor: 'gray.100',
      zIndex: 1,
      boxShadow: 'var(--chakra-shadows-outline)',
    }}
    _hover={{ textDecor: 'none', bgColor: 'gray.100' }}
  >
    {activite.title}
  </Link>
);

const EclaireurResult = ({
  closeModal,
  eclaireur,
  isSuggestedOption,
}: {
  closeModal: () => void;
  eclaireur: User;
  isSuggestedOption?: boolean;
}) => (
  <Link
    data-cy="eclaireur-search-result"
    href={eclaireurPath(eclaireur._id)}
    onClick={() => {
      pushEvent(
        GTM_CATEGORY_RECHERCHE,
        GTM_ACTION_RECHERCHE,
        'recherche eclaireur',
        eclaireur.profile.firstName + '_' + eclaireur.profile.lastName
      );
      closeModal();
    }}
    display="flex"
    alignItems="center"
    borderRadius={4}
    textDecor="none"
    px={3}
    py={2}
    fontWeight="normal"
    bgColor={isSuggestedOption && 'gray.100'}
    _focus={{
      bgColor: 'gray.100',
      zIndex: 1,
      boxShadow: 'var(--chakra-shadows-outline)',
    }}
    _hover={{ textDecor: 'none', bgColor: 'gray.100' }}
  >
    <HStack spacing="6">
      <Avatar
        size="md"
        name={eclaireur.profile?.firstName}
        src={userProfileImage(eclaireur)}
      />
      <Stack spacing="-5">
        <Text mt="0">{`${eclaireur.profile.firstName}${
          eclaireur.profile.lastName ? ` ${eclaireur.profile.lastName}` : ''
        }`}</Text>
        {eclaireur.profile?.pisteName ? (
          <Text fontSize="smaller" color="gray.500">
            {eclaireur.profile?.pisteName}
          </Text>
        ) : null}
      </Stack>
    </HStack>
  </Link>
);

const handleKeyDown = (e) => {
  if (e.keyCode === 40) {
    e.preventDefault();
    focusNext(e);
  } else if (e.keyCode === 38) {
    e.preventDefault();
    focusPrevious(e);
  }
};

const SearchModalContent = ({ closeModal }) => {
  const router = useRouter();
  const [isFocusedOnInput, setIsFocusedOnInput] = useState(true);
  const { isProf, isLyceen } = useUser();
  const { classe, classeIsGatlRc } = useClasse();
  const { myProfile } = useMyProfile();
  const filterType = isGatl(myProfile) || isRc(myProfile) ? 'BP' : 'GT';
  const isElevesEnabled = isProf && classe;
  const isActivitesEnabled = classeIsGatlRc && (isProf || (isLyceen && classe));

  // There is also a label in FakeSearchInput.tsx
  const placeholder = isElevesEnabled
    ? 'Rechercher une piste, un article, une activité, un élève, un éclaireur'
    : isActivitesEnabled
    ? 'Rechercher une piste, un article, une activité, un éclaireur'
    : 'Rechercher une piste, un article ou un éclaireur';

  const [searchInput, setSearchInput] = useState('');

  const allEleves = classe?.eleves ?? [];
  const { modules } = useModules();

  const d = useAutocomplete({ query: searchInput, filterType });
  const lowerSearchInput = lowerAscii(searchInput);

  const allActivites = modules?.flatMap((m) =>
    m.activites.map((a) => ({ ...a, moduleId: m.moduleId }))
  );

  // TODO: If lyceen, filter by what they have access to
  const filteredActivites =
    isActivitesEnabled && allActivites?.length > 0
      ? allActivites
          .filter((a) => lowerAscii(a.title ?? '').includes(lowerSearchInput))
          .slice(0, 6)
      : [];

  const filteredPistes = d.data?.pistes || [];
  const filteredEleves = isElevesEnabled
    ? allEleves
        .filter(
          (e) =>
            lowerAscii(e.profile?.firstName ?? '').includes(lowerSearchInput) ||
            lowerAscii(e.profile?.lastName ?? '').includes(lowerSearchInput) ||
            lowerSearchInput.includes(lowerAscii(e.profile?.firstName ?? '')) ||
            lowerSearchInput.includes(lowerAscii(e.profile?.lastName ?? ''))
        )
        .sort((a, b) =>
          (a.profile?.lastName ?? '').localeCompare(b.profile?.lastName ?? '')
        )
    : [];

  const filteredArticles = d.data?.articles || [];
  const filteredEclaireurs = d.data?.eclaireurs || [];

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => document.removeEventListener('keydown', handleKeyDown, false);
  }, []);

  const handleSubmit = (e) => {
    pushEvent(
      GTM_CATEGORY_RECHERCHE,
      GTM_ACTION_RECHERCHE,
      'Appui sur entrée',
      searchInput
    );
    e.preventDefault();

    if (isFocusedOnInput) {
      router.push(recherchePath(searchInput));
      closeModal();
    }
  };

  return (
    <>
      <ModalHeader fontSize="lg" fontWeight="bold">
        Recherche
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={8}>
        <form onSubmit={handleSubmit}>
          <InputGroup>
            <InputLeftElement pointerEvents="none" color="gray.400">
              <Icon as={SearchIcon} boxSize={5} />
            </InputLeftElement>
            <Input
              data-cy="search-textbox"
              placeholder={placeholder}
              value={searchInput}
              onFocus={() => setIsFocusedOnInput(true)}
              onBlur={() => setIsFocusedOnInput(false)}
              onChange={(e) => setSearchInput(e.target.value)}
              autoFocus
              pl={9}
            />
          </InputGroup>
        </form>
        {searchInput.length >= 2 && !d.isFetching && (
          <Box mt={5}>
            {isElevesEnabled && filteredEleves.length > 0 && (
              <>
                <Heading size="xs" my={3} as="h4">
                  Élèves
                </Heading>
                {filteredEleves.map((e) => (
                  <EleveResult key={e._id} eleve={e} closeModal={closeModal} />
                ))}
              </>
            )}
            {isActivitesEnabled && filteredActivites.length > 0 && (
              <>
                <Heading size="xs" my={3} as="h4">
                  Activités
                </Heading>
                {filteredActivites.map((a) => (
                  <ActiviteResult
                    key={a.activiteId}
                    activite={a}
                    closeModal={closeModal}
                  />
                ))}
              </>
            )}
            {filteredPistes.length > 0 && (
              <>
                <Heading size="xs" my={3} as="h4">
                  Pistes
                </Heading>
                {filteredPistes.map((p) => (
                  <PisteResult key={p._id} piste={p} closeModal={closeModal} />
                ))}
              </>
            )}
            {filteredArticles.length > 0 && (
              <>
                <Heading size="xs" my={3} as="h4">
                  Articles
                </Heading>
                {filteredArticles.map((a) => (
                  <ArticleResult
                    key={a._id}
                    article={a}
                    closeModal={closeModal}
                  />
                ))}
              </>
            )}
            {filteredEclaireurs.length > 0 ? (
              <>
                <Heading size="xs" my={3} as="h4">
                  Éclaireurs
                </Heading>
                {filteredEclaireurs.map((e) => (
                  <EclaireurResult
                    key={e._id}
                    eclaireur={e}
                    closeModal={closeModal}
                  />
                ))}
              </>
            ) : null}
          </Box>
        )}
        {d.isFetching ? (
          <Center>
            <Spinner size="xl" thickness="4px" mt={8} />
          </Center>
        ) : (
          <>
            {searchInput.length >= 2 &&
              filteredEleves.length === 0 &&
              filteredPistes.length === 0 &&
              filteredArticles.length === 0 &&
              filteredActivites.length === 0 &&
              filteredEclaireurs.length === 0 && (
                <Center mt={8}>
                  Aucun résultat ne correspond à votre recherche.
                </Center>
              )}
          </>
        )}
      </ModalBody>
    </>
  );
};

export default SearchModalContent;
